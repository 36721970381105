import React, { useState } from 'react';
import classNames from 'classnames';
import KeypadNumber from '../KeypadNumber';

const Keypad = () => {
  const [code, setCode] = useState('');

  const onNumberSelected = number => {
    if (code.length >= 4) {
      return;
    }

    setCode(code + number);
  };

  const onReset = () => setCode('');

  const submit = async ({ arm }) => {
    setCode('');
    await fetch(`https://home.gautier.im/api/webhook/${arm ? 'arm_alarm' : 'disarm_alarm'}`, {
      method: 'POST',
      //mode: 'no-cors',
      body: JSON.stringify({ code: parseInt(code, 10) }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  };

  return (
    <div className='keypad-wrapper'>
      <div className='input-wrapper'>
        {[0, 1, 2, 3].map(part => (
          <div key={part} className={classNames('number-input', {
            'no-circle': !!code[part]
          })}>{code[part] ? '*' : ''}</div>
        ))}
      </div>
      <div className={'actions'}>
        <button onClick={() => onReset()}>Reset</button>
        <button disabled={code.length !== 4} onClick={() => submit({ arm: false })}>Disarm</button>
        <button disabled={code.length !== 4} onClick={() => submit({ arm: true })}>Arm</button>
      </div>
      <div className='keypad'>
        <div className='number-line'>
          <KeypadNumber number={1} onClick={onNumberSelected} />
          <KeypadNumber number={2} onClick={onNumberSelected} />
          <KeypadNumber number={3} onClick={onNumberSelected} />
        </div>
        <div className='number-line'>
          <KeypadNumber number={4} onClick={onNumberSelected} />
          <KeypadNumber number={5} onClick={onNumberSelected} />
          <KeypadNumber number={6} onClick={onNumberSelected} />
        </div>
        <div className='number-line'>
          <KeypadNumber number={7} onClick={onNumberSelected} />
          <KeypadNumber number={8} onClick={onNumberSelected} />
          <KeypadNumber number={9} onClick={onNumberSelected} />
        </div>
        <div className='number-line'>
          <KeypadNumber number={0} onClick={onNumberSelected} />
        </div>
      </div>
    </div>
  );
};

export default Keypad;
